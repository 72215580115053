<template>
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 300 300"
    style="enable-background: new 0 0 300 300"
    xml:space="preserve"
  >
    <g>
      <g>
        <polygon class="st0" points="150,294.5 5.5,150 150,5.5 294.5,150" />
        <g>
          <path
            class="st1"
            d="M150,300L0,150L150,0l150,150L150,300z M2.1,150L150,297.9L297.9,150L150,2.1L2.1,150z"
          />
        </g>

        <rect
          x="101.6"
          y="101.6"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -62.1119 150.0486)"
          class="st2"
          width="96.9"
          height="96.9"
        />
        <path
          class="st3"
          d="M226,112l-22.8,22.8L180.5,112H150h-30.5H89H43.5l22.8,22.8c0,0,18,0,22.8,0V188h30.5v-53.3
			c12.3,0,15.5,0,30.5,0V188h30.5v-45.5l22.8,22.8l22.8-22.8V188h30.5v-53.3V112H226z"
        />
      </g>
    </g>
  </svg>
</template>

<style scoped>
.st0 {
  fill: #acacac;
}

.st1 {
  fill: #32446a;
}

.st2 {
  fill: #ffffff;
}

.st3 {
  fill: #003471;
}
</style>
